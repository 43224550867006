<template>
  <div>
    <div class="header">
      <van-nav-bar
        title="利息宝"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent" style="background: #181e34;">
		<van-row>
			<van-list
			  v-if="orders.length"
			  v-model="loading"
			  :finished="finished"
			  :finished-text="$t('common.nomore')"
			>
				<table id="tabHold">
					<thead>
						<tr>
							<th>投资期限</th>
							<th>投资效益</th>
							<th>起投金额</th>
							<th>操作</th>
						</tr>
					</thead>
					<tbody class="">
						<tr v-for="(item,index) in orders" :key="index">
							<td>{{item.days}}天</td>
							<td>{{item.rates}}{{item.type==2?'%':''}}</td>
							<td>{{item.min}}元</td>
							<td>
								<span @click="todetail(item)" class="doInvest forJsClick">投资</span>
							</td>
						</tr>
					</tbody>
				</table>
			</van-list>
			<van-empty
			  :description="$t('common.nomore')"
			  v-else
			/>
		</van-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orders: [],
      recharges: [],
      withdraws: [],
      transfers: [],
      finished: true,
      loading: false,
	  ordertype:1,
    }
  },
  created() {
    /* this.getRecharges()
    this.getTransfers() */
	this.getOrders();
  },
  mounted() {},
  methods: {
	  todetail(item){
		this.$router.push("/idetail/"+item.id);  
	  },
    // 创新记录
    async getOrders() {
      const { data } = await this.$http.get('/home/user/investlist')
      if (data) {
        if (data.code === 200) {
          this.orders = data.data
        }
      }
    },
  }
}
</script>
<style lang="less" scoped>
	#tabHold{
		width: calc(100% - 16px);
		margin: 0 8px;
		border-collapse: collapse;
	}
	#tabHold thead{
		
	}
	#tabHold thead tr{
		color: #cad0e6;
		text-align: center;
		border-top: 1px solid #8686aa;
		border-bottom: 1px solid #8686aa;
	}
	#tabHold thead tr th{
		height: 2.7rem;
		border-right: 1px solid #8686aa;
		vertical-align: middle;
		font-size: 0.8rem;
	}
	#tabHold tbody tr{
		color: #cad0e6;
		text-align: center;
		border-top: 1px solid #8686aa;
		border-bottom: 1px solid #8686aa;
	}
	#tabHold tbody tr td{
		height: 2.7rem;
		border-right: 1px solid #8686aa;
		vertical-align: middle;
		font-size: 0.8rem;
	}
	#tabHold tr td:last-child, #tabHold tr th:last-child {
	    border-right: none;
	}
	#tabHold tbody td span {
	    background: #ff571e;
	    padding: 0.3rem 0.6rem;
	    border-radius: 0.6rem;
	    color: #cad0e6;
	}
.maincontent {
  padding: 46px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #fff;
  height: 100vh;
  /deep/.van-tab {
    font-size: 1.04rem;
  }
  .currency-Increase {
    color: #8686aa;
    padding: 0.8rem 1.33333rem 0.8rem 1.33333rem;
    line-height: 1.6rem;
    font-size: 0.8rem;
    display: flex;
    border-bottom: 1px solid #23293f;
    .currency-time {
      text-align: right;
    }
  }
}
</style>
